import * as yup from 'yup'
import { getLocalISOString } from '../utils/customDateFormats'
import { calculateRemainingHours } from '../utils/calculateRemainingHours'
import { INPUT_TYPES } from './index'
import dayjs from 'dayjs'

export const OSTEDHY_FORM_CONFIG = {
  scheduleSession: {
    name: 'Session',
    initial_state: data => ({
      name: data?.name || '',
      group_session_id: data?.groupSessions || null,
      price: data?.price || '',
      recording_price: data?.recording_price || '',
      sessionTypes: data?.sessionTypes || null,
      webinar: data?.webinarAccounts || null,
      date: data?.start_date || '',
    }),
    data: (selected, startDate) => ({
      group_session_id: selected?.group_session_id?.id,
      name: selected?.name,
      price: selected?.price,
      recording_price: selected?.recording_price,
      session_type_id: selected?.sessionTypes.id,
      start_date: startDate,
      webinar_account_id: selected?.webinar?.id,
    }),
    schema: yup.object().shape({
      name: yup.string().required('Session Name is required'),
      date: yup
        .date()
        .required('Date is required')
        .min(new Date(), 'Date must be greater than today'),
      group_session_id: yup.object().required('Group session is required'),
      price: yup.number().required('Price is required'),
      recording_price: yup.number().required('Recording price is required'),
      sessionTypes: yup.object().required('Streaming tool is required'),
      webinar: yup.object().when('sessionTypes', {
        is: val => val?.name.toLowerCase() === 'webinar',
        then: () => yup.object().required('Webinar is required'),
        otherwise: () => yup.object().nullable(),
      }),
    }),
    fields: [
      {
        type: INPUT_TYPES.date,
        label: 'Date',
        id: 'date',
      },
      {
        type: INPUT_TYPES.asyncSelect,
        label: 'Group Session',
        id: 'group_session_id',
        optionDisplayAttribute: 'name',
        searchAttribute: 'name',
      },
      {
        type: INPUT_TYPES.groupSession,
        label: 'Create a group session',
        id: 'group_session',
      },
      {
        type: INPUT_TYPES.number,
        label: 'Price',
        id: 'price',
      },
      {
        type: INPUT_TYPES.number,
        label: 'Recording Price',
        id: 'recording_price',
      },
      {
        type: INPUT_TYPES.asyncSelect,
        label: 'Streaming Tool',
        id: 'sessionTypes',
        optionDisplayAttribute: 'name',
        searchAttribute: 'name',
      },
      {
        type: INPUT_TYPES.asyncSelect,
        label: 'Webinars',
        optionDisplayAttribute: 'email',
        id: 'webinar',
        searchAttribute: 'email',
      },
    ],
  },
  groupSession: {
    name: 'Group Session',
    initial_state: () => ({
      name: '',
      levelIds: [],
      subjectsOfTeacher: null,
      teacherId: null,
    }),
    data: selected => ({
      name: selected?.name,
      student_level_id: selected?.levelIds?.id,
      subjects: selected?.subjectsOfTeacher?.map(subject => subject?.id),
      teacher_id: selected?.teacherId?.id,
    }),
    schema: yup.object().shape({
      name: yup.string().required('Name is required'),
      teacherId: yup.object().required('Teacher is required'),
      subjectsOfTeacher: yup
        .array()
        .of(yup.mixed())
        .required('Subjects are required'),
      levelIds: yup.object().required('Level is required'),
    }),
    fields: [
      {
        type: INPUT_TYPES.asyncSelect,
        label: 'Teacher',
        optionDisplayAttribute: 'first_name,last_name',
        id: 'teacherId',
        searchAttribute: 'first_name',
      },
      {
        type: INPUT_TYPES.asyncMultiSelect,
        label: 'Subjects',
        id: 'subjectsOfTeacher',
        optionDisplayAttribute: 'name',
        searchAttribute: 'name',
      },
      {
        type: INPUT_TYPES.asyncSelect,
        label: 'Student Level',
        id: 'levelIds',
        optionDisplayAttribute: 'name',
        searchAttribute: 'name',
      },
    ],
  },
}

export const TAKI_FORM_CONFIG = {
  scheduleSession: {
    name: 'Event',
    initial_state: data => ({
      name: data?.name || '',
      offerIds: data?.offers || [],
      classIds: data?.classes || [],
      levelIds: data?.levels || null,
      subjectId: data?.subjects || null,
      teacherId: data?.teacher || null,
      streamingTool: null,
      isFromCalendar: true,
      webinar: data?.webinar_account || null,
      date: data?.start_date ? getLocalISOString(data?.start_date) : null,
      time: data?.end_date
        ? calculateRemainingHours(data?.start_date, data?.end_date)
        : dayjs().startOf('day').add(2, 'hour'),
      isBigZoom: data?.is_big_zoom_event || 0,
      url: data?.url || '',
      group_session_id: data?.traineeship || null,
      canceled: data?.canceled == true ? 1 : 0,
      is_draft: data?.is_draft == true ? 1 : 0,
      active: data?.active ? (data?.active == true ? 1 : 0) : 1,
      //test reason: data?.reason || null,
    }),
    data: {
      event: (selected, startDate, endDate) => ({
        startDate: startDate,
        endDate: endDate,
        isBigZoomEvent: selected?.isBigZoom,
        webinarAccount: `${selected.webinar?.id}`,
        subjectEvents: selected.subjectId?.map(sub => ({
          subject: sub.id,
        })),
        reason: selected?.reason,
        content: {
          name: selected.name,
          contentStudentLevels: selected.levelIds?.map(level => {
            return {
              studentLevel: `${level?.id}`,
            }
          }),
          type: 5,
          isDraft: Number(selected?.is_draft),
          active: Number(selected?.active),
          isEventTitleApproved: 0,
          instructor: `${selected.teacherId?.id}`,
          groupContents: selected.offerIds?.map(offer => {
            return { groups: offer?.id }
          }),
        },
        isFromCalendar: true,
        traineeship: selected.traineeship?.id || null,
        canceled: Number(selected.canceled),
        url: selected?.url,
      }),
      hessaty: (selected, startDate, endDate) => ({
        startDate: startDate,
        endDate: endDate,
        isBigZoomEvent: selected?.isBigZoom,
        webinarAccount: `${selected.webinar?.id}`,
        traineeship: selected?.group_session_id?.id,
        subjectEvents: selected.subjectId?.map(sub => ({
          subject: sub.id,
        })),
        content: {
          name: selected.name,
          contentStudentLevels: selected.levelIds?.map(level => {
            return {
              studentLevel: `${level?.id}`,
            }
          }),
          type: 5,
          isDraft: Number(selected?.is_draft),
          active: Number(selected?.active),
          instructor: `${selected.group_session_id?.instructor?.id}`,
          groupContents: selected.offerIds?.map(offer => {
            return { groups: `${offer?.id}` }
          }),
        },
        canceled: 0,
        url: selected?.url,
      }),
    },
    schema: {
      event: yup.object().shape({
        name: yup.string().required('Session Name is required'),
        date: yup
          .date()
          .required('Date is required')
          .min(new Date(), 'Date must be greater than today'),
        time: yup.date().required('Time is required'),
        offerIds: yup.array().min(1, 'Offers are required'),
        subjectId: yup.array().min(1, 'Subjects are required'),
        levelIds: yup.array().nullable(),
        teacherId: yup.object().required('Teacher is required'),
        webinar: yup.object().required('Webinar is required'),
        isBigZoom: yup.boolean(),
        canceled: yup.boolean(),
        draft: yup.boolean(),
        active: yup.boolean(),
        reason: yup.string(),
      }),
      hessaty: yup.object().shape({
        name: yup.string().required('Name is required'),
        date: yup
          .date()
          .required('Date is required')
          .min(new Date(), 'Date must be greater than today'),
        time: yup.date().required('Time is required'),
        group_session_id: yup.object().required('Hessaty chapter is required'),
        subjectId: yup.array().min(1, 'Subjects are required'),
        webinar: yup.object().required('Webinar is required'),
        isBigZoom: yup.boolean(),
        draft: yup.boolean(),
        active: yup.boolean(),
      }),
    },
    fields: {
      event: [
        {
          type: INPUT_TYPES.date,
          label: 'Date',
          id: 'date',
        },
        {
          type: INPUT_TYPES.time,
          label: 'Time',
          id: 'time',
        },
        {
          type: INPUT_TYPES.asyncSelect,
          label: 'Teacher',
          id: 'teacherId',
          optionDisplayAttribute: 'name,last_name',
          searchAttribute: 'name',
          searchJoin: 'or',
        },
        {
          type: INPUT_TYPES.asyncSelect,
          label: 'Webinars',
          id: 'webinar',
          optionDisplayAttribute: 'email',
          searchAttribute: 'email',
          subField: {
            type: INPUT_TYPES.checkbox,
            label: 'Is Big Zoom Account?',
            id: 'isBigZoom',
          },
        },
        {
          type: INPUT_TYPES.asyncMultiSelect,
          label: 'Offers',
          id: 'offerIds',
          optionDisplayAttribute: 'name',
          searchAttribute: 'name',
        },
        {
          type: INPUT_TYPES.asyncMultiSelect,
          label: 'Subjects',
          optionDisplayAttribute: 'name',
          id: 'subjectId',
          searchAttribute: 'name',
        },
        {
          type: INPUT_TYPES.asyncMultiSelect,
          label: 'Levels',
          id: 'levelIds',
          optionDisplayAttribute: 'name',
          searchAttribute: 'name',
        },

        {
          type: INPUT_TYPES.checkbox,
          label: 'Draft',
          id: 'is_draft',
        },
        {
          type: INPUT_TYPES.checkbox,
          label: 'Active',
          id: 'active',
        },
        // {
        //   type: INPUT_TYPES.checkbox,
        //   label: 'Canceled',
        //   id: 'canceled',
        //   subField: {
        //     //* Add Text Input to Input types and use it *//
        //     type: INPUT_TYPES[Text input],
        //     label: 'Reason',
        //     id: 'reason',
        //   },
        // },
      ],
      hessaty: [
        {
          type: INPUT_TYPES.date,
          label: 'Date',
          id: 'date',
        },
        {
          type: INPUT_TYPES.time,
          label: 'Time',
          id: 'time',
        },
        {
          type: INPUT_TYPES.asyncMultiSelect,
          label: 'Subjects',
          optionDisplayAttribute: 'name',
          id: 'subjectId',
          searchAttribute: 'name',
        },
        {
          type: INPUT_TYPES.asyncSelect,
          label: 'Hesssaty Chapter',
          id: 'group_session_id',
          optionDisplayAttribute: 'name',
          searchAttribute: 'name',
        },
        {
          type: INPUT_TYPES.groupSession,
          label: 'Create a group session',
          id: 'group_session',
          searchAttribute: 'name',
        },
        {
          type: INPUT_TYPES.asyncSelect,
          label: 'Webinars',
          id: 'webinar',
          optionDisplayAttribute: 'email',
          searchAttribute: 'email',
        },
        {
          type: INPUT_TYPES.checkbox,
          label: 'Is Big Zoom Account?',
          id: 'isBigZoom',
        },

        {
          type: INPUT_TYPES.checkbox,
          label: 'Draft',
          id: 'is_draft',
        },
        {
          type: INPUT_TYPES.checkbox,
          label: 'Active',
          id: 'active',
        },
      ],
    },
  },
  groupSession: {
    name: 'Group Session',
    initial_state: () => ({
      name: '',
      offerIds: [],
      levelIds: [],
      subjectIds: [],
      teacherId: null,
      totalSessions: null,
      price: null,
      versPilote: false,
    }),
    data: selected => ({
      name: selected?.name,
      price: selected?.price,
      totalseances: selected?.totalSessions,
      saleable: true,
      instructor: selected?.teacherId?.id,
      traineeshipGroups: selected?.offerIds?.map(offer => ({
        group: offer?.id,
      })),

      traineeshipLevels: selected?.levelIds?.map(level => ({
        level: level?.id,
      })),
      traineeshipSubjects: selected?.subjectId?.map(subject => ({
        subject: subject?.id,
      })),
      versPilote: selected?.versPilote,
    }),
    schema: yup.object().shape({
      name: yup.string().required('Name is required'),
      offerIds: yup.array().min(1, 'Offers are required'),
      levelIds: yup.array().nullable(),
      subjectId: yup.array().of(yup.mixed()).required('Subjects are required'),
      teacherId: yup.object().required('Teacher is required'),
      totalSessions: yup.number().required('Total Sessions is required'),
      price: yup.number().required('The Price is required'),
      versPilote: yup.boolean(),
    }),
    fields: [
      {
        type: INPUT_TYPES.asyncMultiSelect,
        label: 'Offers',
        id: 'offerIds',
        optionDisplayAttribute: 'name',
        searchAttribute: 'name',
      },

      {
        type: INPUT_TYPES.asyncMultiSelect,
        label: 'Subjects',
        id: 'subjectId',
        optionDisplayAttribute: 'name',
        searchAttribute: 'name',
      },
      {
        type: INPUT_TYPES.asyncSelect,
        label: 'Teacher',
        optionDisplayAttribute: 'name,last_name',
        id: 'teacherId',
        searchAttribute: 'name',
      },
      {
        type: INPUT_TYPES.asyncMultiSelect,
        label: 'Levels',
        id: 'levelIds',
        optionDisplayAttribute: 'name',
        searchAttribute: 'name',
      },
      {
        type: INPUT_TYPES.number,
        label: 'Total Sessions',
        id: 'totalSessions',
      },
      {
        type: INPUT_TYPES.number,
        label: 'Price',
        id: 'price',
      },
      {
        type: INPUT_TYPES.checkbox,
        label: 'Vers Pilote',
        id: 'versPilote',
      },
    ],
  },
}
